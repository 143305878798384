import request from '@/utils/request'
const requestApi = {
  shop_category_list: '/shop/back_goods_category/',
  shop_category_create: '/shop/back_goods_category/',
  shop_category_update: '/shop/back_goods_category/',
  shop_category_delete: '/shop/back_goods_category/',
  shop_sync_category: '/shop/sync_goods_category/'
}

export function goods_category_list (query) {
  return request({
    url: requestApi.shop_category_list,
    method: 'get',
    params: query
  })
}

export function goods_category_create (data) {
  return request({
    url: requestApi.shop_category_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function goods_category_update (data, goods_id) {
  return request({
    url: requestApi.shop_category_update + goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function goods_category_delete (goods_id) {
  return request({
    url: requestApi.shop_category_delete + goods_id + '/',
    method: 'delete'
  })
}

export function sync_goods_category (data) {
  return request({
    url: requestApi.shop_sync_category,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
