import request from '@/utils/request'

export function listGoods (query) {
  return request({
    url: '/shop/back_goods/',
    method: 'get',
    params: query
  })
}

export function deleteGoods (id) {
  return request({
    url: '/shop/back_goods/' + id + '/',
    method: 'delete'
  })
}

/* // 创建页面获取商品列表
  export function getWxMallCommodityList (query) {
  return request({
    url: 'wechat/wechat_mall_commodity/list/',
    method: 'get',
    params: query
  })
} */
// 是否是 自有仓库的公司
export function IsPublic (query) {
  return request({
    url: '/wms/is_supervised/',
    method: 'get',
    params: query
  })
}

// 商品会员价
export function shopGoodsPriceList (query) {
  return request({
    url: '/shop/goods/back_price/',
    method: 'get',
    params: query
  })
}
// 商品会员价
export function shopGoodsPriceCreate (data) {
  return request({
    url: '/shop/goods/back_price/',
    method: 'post',
    data: data
  })
}

// 商品会员价
export function shopGoodsPriceEdit (data, id) {
  return request({
    url: '/shop/goods/back_price/' + id + '/',
    method: 'put',
    data: data
  })
}

// 商品会员价
export function shopGoodsPriceDelete (id) {
  return request({
    url: '/shop/goods/back_price/' + id + '/',
    method: 'delete'
  })
}
// 上架模糊获取商品列表公共
export function getWxMallCommonCommodityList (query) {
  return request({
    url: '/wms/supervised_warehouse/goods/',
    method: 'get',
    params: query
  })
}

export function publishGoods (data) {
  return request({
    url: '/shop/back_goods/',
    method: 'post',
    data
  })
}

export function detailGoods (query) {
  return request({
    url: '/shop/back_goods/',
    method: 'get',
    params: query
  })
}

export function detailGoodsGallery (query) {
  return request({
    url: '/mall/goods_gallery/',
    method: 'get',
    params: query
  })
}

export function editGoods (data, id) {
  return request({
    url: '/shop/back_goods/' + id + '/',
    method: 'put',
    data
  })
}

export function listCatAndBrand () {
  return request({
    url: '/goods/catAndBrand',
    method: 'get'
  })
}

export function listCat () {
  return request({
    url: '/wechat/category/name/',
    method: 'get'
  })
}

// 商品详情页滚动图
export function shopGoodsGalleryList (query) {
  return request({
    url: '/shop/goods/back_gallery/',
    method: 'get',
    params: query
  })
}
// 商品详情页滚动图
export function shopGoodsGalleryCreate (data) {
  return request({
    url: '/shop/goods/back_gallery/',
    method: 'post',
    data: data
  })
}

// 商品详情页滚动图
export function shopGoodsGalleryEdit (data, id) {
  return request({
    url: '/shop/goods/back_gallery/' + id + '/',
    method: 'put',
    data: data
  })
}

// 商品详情页滚动图
export function shopGoodsGalleryDelete (id) {
  return request({
    url: '/shop/goods/back_gallery/' + id + '/',
    method: 'delete'
  })
}
